@use 'sass:math';
@import '/assets/styles/utils.scss';

.collage-module {
    $self: &;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    &--border {
        position: relative;
        #{$self}__box-wrapper {
            border-bottom: 1px solid var(--gray-60);
        }
        @media (min-width: 768px) {
            #{$self}__box-wrapper:first-child {
                border-right: 1px solid var(--gray-60);
            }
        }
    }

    &--fill-height {
        @media (max-width: 768px) {
            min-height: min(
                calc(100vh - var(--header-height)),
                750px
            );

            &#{$self}--is-hero {
                min-height: calc(125vh - var(--header-height));
            }
        }
    }

    // Format - whether we force the boxes square, or naturally expand to follow content
    &--square-format {
        @media (max-width: 768px) {
            #{$self}__box-wrapper--fill-height-on-mobile {
                min-height: calc(90vh - var(--header-height));
                max-width: 100%
            }
            // #{$self}__box-wrapper {
            //     flex: 1;
            // }
        }
        @media (min-width: 768px) {
            // min-height: 600px;
            min-height: calc(100vh - var(--header-height) - var(--module-spacing));
            max-height: 1000px;
            #{$self}__box {
                width: 100%;
                min-height: 100%;
                // position: absolute;
                // bottom: 0;

                &-wrapper {
                    // padding-bottom: 50%;
                    // height: calc(100vh - var(--header-height));
                    // max-height: 100vw;
                    // min-height: min(
                    //     calc(92vh - var(--header-height)),
                    //     1000px
                    // );
                    position: relative;
                    width: 100%;
                }
            }
        }
    }
    &--medium-format {
        @media (max-width: 768px) {
            #{$self}__box-wrapper--fill-height-on-mobile {
                min-height: 540px;
                max-height: calc(90vh - var(--header-height));
                max-width: 100%;
            }
        }
        @media (min-width: 768px) {
            #{$self}__box-wrapper {
                min-height: 640px;
            }
            #{$self}__box {
                height: 100%;

                &-wrapper {
                    width: 50%;
                }
            }
        }
    }
    &--small-format {
        @media (min-width: 768px) {
            #{$self}__box-wrapper {
                min-height: 400px;
            }
            #{$self}__box__media-wrapper:not(#{$self}__box__media-wrapper-position-background-fill) {
                max-height: 480px;
            }
            #{$self}__box {
                height: 100%;
                &-wrapper {
                    width: 50%;
                }
            }
        }
    }
    &--no-minimum-format {
        @media (min-width: 768px) {
            #{$self}__box {
                height: 100%;
                &-wrapper {
                    width: 50%;
                }
            }
        }

        #{$self}__box__media-wrapper {
            position: relative;
            picture, img {
                position: relative;
            }
        }
    }

    &--content-right {
        opacity: 1;
        @media (max-width: 768px) {
            flex-direction: column-reverse;
            justify-content: flex-end;
        }
    }

    &--is-hero #{$self}__box {
        @media (max-width: 768px) {
            padding-top: 16px;
        }
    }

    &--single-box {
        // justify-content: center;

        @media (min-width: 768px) {
            #{$self}__box-wrapper {
                width: 100%;
            }
            #{$self}__box--center {
                margin-left: auto;
                margin-right: auto;
            }
            #{$self}__box--narrow {
                width: calc(0.75 * min(100vw, 1680px)) !important;
                max-width: none;
                margin: 0 auto;
            }
        }
        @media (min-width: 1024px) {
            #{$self}__box {
                --padding: 40px;
            }
        }
        @media (max-width: 768px) {
            #{$self}__box-wrapper {
                height: 100%;
                display: flex;
            }
        }
    }

    &--boxed {
        position: relative;
        // margin: 20px var(--margin-spacing);
        // border-radius: 16px;
        overflow: hidden;
        // padding: 0 12px;
        @media (min-width: 1680px) {
            // padding: 0 20px;
            // max-width: calc(1680px - 2 * 40px);
            // margin-left: auto;
            // margin-right: auto;
            // box-sizing: border-box;
        }
    }

    &__background {
        position: relative;
    }

    &__background-media-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 0;

        &:global(.position-background-fill), &:global(.position-contain) {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }

        &:global(.position-background-fill) {
            :global([class*='BasicImage']), :global(.video-wrapper video), img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &:global(.position-contain) {
            :global([class*='BasicImage']), :global(.video-wrapper video), img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &__box-wrapper {
        &--media-position-background-fill, &--media-position-contain {
            @media (max-width: 768px) {
                position: relative;
                // width: 100%;
                // aspect-ratio: 1;
                min-height: 100vw;
                #{$self}__box {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
            }
        }
        &--has-mobile-media {
            @media (max-width: 768px) {
                aspect-ratio: 3/4;
            }
        }

        &--boxed {
            @media (min-width: 768px) {
                margin: 20px 0;
                &:first-child {
                    padding: 0 10px 0 var(--margin-spacing);
                }
                &:last-child {
                    padding: 0 var(--margin-spacing) 0 10px;
                }
            }
            #{$self}__box {
                // border-radius: 16px;
                @media (max-width: 768px) {
                    margin-left: var(--margin-spacing);
                    margin-right: var(--margin-spacing);
                    &#{$self}__box--top {
                        margin-bottom: 20px;
                        margin-top: 20px;
                    }
                    &#{$self}__box--bottom {
                        margin-bottom: 20px;
                    }
                    padding: var(--padding) 24px;

                    #{$self}--square-format & {
                        min-height: min(
                            calc(80vh - var(--header-height) - 32px),
                            600px
                        )
                    }
                    // &#{$self}__box--second {
                        // @at-root [class*="Container_container--no-margin"] & {
                            // margin-bottom: 0;
                        // }
                    // }

                }
                @media (min-width: 768px) {
                    // margin: 10px 20px;
                    // height: calc(100% - 20px) !important;
                    // width: calc(100% - 30px) !important;
                    // min-height: calc(100% - 20px) !important;
                    // &#{$self}__box--left {
                    //     margin-right: 10px;
                    //     float: right;
                    // }
                    // &#{$self}__box--right {
                    //     margin-left: 10px;
                    //     float: left;
                    // }
                }
                // max-width: 570px;
                // margin-left: max(calc(50vw - (570px / 2)), 0px);
                // @media (min-width: 768px) {
                //     margin-left: max(calc(25vw - (570px / 2)), 0px);
                // }
            }
        }
    }

    &__box {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        --padding: 40px;
        padding: var(--padding) 16px;
        &--top {
            padding-bottom: 16px;
        }
        &--bottom {
            padding-top: 16px;
        }


        @media (min-width: 768px) {
            padding: var(--padding);
        }

        & > * {
            z-index: 1;
        }

        &--center {
            align-items: center;
            text-align: center;
        }
        &--vertically-center {
            // @media (min-width: 768px) {
                // padding-right: 190px;
                justify-content: center;
            // }
        }
        &--narrow {
            &#{$self}__box--left, &#{$self}__box--right {
                max-width: 570px;
                @media (min-width: 768px) {
                    // margin-left: max(calc(50vw - (570px / 2)), 0px);
                    margin-left: max(calc((0.25 * min(100vw, 1680px)) - (570px / 2)), 0px);
                }
            }
        }

        &__content {
            // height: 100%;
        }

        &__sub-title {
            // color: var(--gray-100);
            opacity: 0.7;
            margin-bottom: 16px;
        }
        &__title {
            :global(p) {
                margin: 0;
            }
        }
        &__title:not(:last-child),
        &__body-text:not(:last-child) {
            margin-bottom: 20px;
            @media (min-width: 768px) {
                margin-bottom: 32px;
            }

            &:global(.paragraph-100),
            &:global(.heading-200) {
                margin-bottom: 30px;
            }
        }
        &__cta-group {
            &:not(:last-child) {
                margin-bottom: 40px;
            }
            @media (max-width: 767px) {
                &:not(:last-child) {
                    margin-bottom: 32px;
                }
                span {
                    flex-grow: 1;
                }
            }
        }
        &__cta {
            @media (max-width: 767px) {
                flex-grow: 1;
                box-sizing: border-box;
                text-align: center;
                justify-content: center;
            }
        }

        &__usp {
            @media (max-width: 767px) {
                display: flex;
            }
            @media (min-width: 768px) {
                margin-top: auto;
            }

            &__rolling-text-container {
                display: inline-flex;
                flex-grow: 1;
            }

            li {
                display: flex;
                font-size: 14px;
                margin-bottom: 16px;
            }

            svg {
                width: 24px;
                height: 24px;
                margin-right: 16px;
                align-self: center;
            }
        }

        &__trustpilot:not(:last-child), &__embedded-content:not(:last-child) {
            margin-bottom: 32px;
        }

        &__disclaimer {
            font-size: 0.72em;
            color: var(--gray-100);
            line-height: 160%;
            font-weight: 300;
            a {
                font-weight: 400;
            }
        }

        // Theme the box, for when text and content should be light against a contrast background
        &:global(.text-white) {
            #{$self}__box__disclaimer {
                color: white;
            }
        }

        &__overlay-media-wrapper {
            z-index: 2;

            &#{$self}__box__media-wrapper-position-bottom-left,
            &#{$self}__box__media-wrapper-position-bottom-right {
                position: absolute;
                left: 20px;
                right: 20px;
                bottom: 20px;
                margin-bottom: 0;
                @media (min-width: 768px) {
                    bottom: 40px;
                    left: 40px;
                }
            }
            &#{$self}__box__media-wrapper-position-bottom-right {
                left: auto;
                right: 40px;
            }
            &#{$self}__box__media-wrapper-position-bottom-left,
            &#{$self}__box__media-wrapper-position-bottom-right {
                @media (min-width: 768px) {
                    max-width: 50%;
                }
            }
        }

        &__media-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-self: center;
            line-height: 0;
            flex-grow: 1;

            &-position-background-fill, &-position-contain {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 0;
                align-self: normal;

                picture {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                :global([class*='BasicImage']) {
                    width: 100%;
                    height: 100%;
                }
            }
            &-position-background-fill {
                :global([class*='BasicImage']), :global(.video-wrapper video), img {
                    object-fit: cover;
                }

                #{$self}__box-wrapper--fill-height-on-mobile &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.3) 80%);
                }
            }
            &-position-contain {
                :global([class*='BasicImage']), :global(.video-wrapper video), img {
                    object-fit: contain;
                }
            }
            &-position-center {
                max-width: 80%;
                width: 100%;
                max-height: 620px;
                // max-width: 75%;
                margin-top: auto;
                // padding: 40px 40px 0 40px;
                // padding-top: 20px;
                margin-bottom: auto;
                align-self: center;
                display: flex;
                justify-content: center;
                flex-grow: 1;
                position: relative;

                @media (max-width: 767px) {
                    max-height: 100%;
                    max-width: 100%;
                }
                picture,
                :global([class*='Image_image']),
                :global([class*='BasicImage']) {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    object-fit: contain;
                    @media (min-width: 768px) {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                }
                video, :global(.video-wrapper) {
                    height: auto;
                    max-height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            &-position-bottom {
                max-width: 100%;
                // max-height: 55%;
                padding: 0 40px;
                margin-bottom: calc(-1 * (var(--padding) + 1px));
                margin-top: auto;
                align-self: center;
                width: 100%;
                max-height: 80vh;
                #{$self}--single-box & {
                    max-height: 65vh;
                }
                @media (max-width: 767px) {
                    // max-width: 90%;
                    max-height: 50vh;
                }
                picture {
                    height: 100%;
                }
                :global([class*='BasicImage']) {
                    width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                    @media (min-width: 768px) {
                        position: absolute;
                        left: 0;
                        right: 0;
                        // top: 0;
                        bottom: 0;
                    }
                }
            }
            &-position-bottom-left,
            &-position-bottom-right {
                margin-top: auto;
                max-height: 45%;
                align-self: center;
                flex-grow: 0;
                @media (min-width: 768px) {
                    max-width: min(80%, 420px);
                }
                @media (max-width: 767px) {
                    max-width: 100%;
                    margin-bottom: -24px;
                }
                :global([class*='BasicImage']),
                :global([class*='Image_image']) {
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            &-position-bottom-left {
                align-self: flex-start;
            }
            &-position-bottom-right {
                align-self: flex-end;
            }
            &-position-above-text {
                order: -1;
            }

            picture {
                width: 100%;
                min-height: 1px;
            }

            &-animation-notification {
                opacity: 0;
            }
        }
        &__media {
        }
    }

    &--in-view {
        #{$self}__box__media-wrapper-animation-notification {
            animation: notification 0.5s forwards ease;
            animation-delay: 0.5s;
        }
    }

    :global(canvas) {
        width: 100% !important;
        max-width: 350px !important;
        height: auto !important;
    }
}

@keyframes notification {
    0% {
        transform:  translateY(75%) scale3d(0.1, 0.1, 1);
        opacity: 0;
    }

    100% {
        transform:  translateY(0%) scale3d(1, 1, 1);
        opacity: 1;
    }
}
