.trustpilot-container {
    width: 100%;
    overflow: hidden;
}
.trustpilot {
    display: inline-flex;
    gap: 0.5em;
    align-items: center;
    font-size: 1em;
    transform-origin: bottom left;
}
.trustpilot > * {
    flex-shrink: 0;
}
