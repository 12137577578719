.rolling-text {
    position: relative;
    display: inline-flex;
    transition: width 0.2s, height 0.2s;
    width: 100%;

    &__item {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        // align-items: center;
        &--hidden {
            visibility: hidden;
        }
    }

    &-animation-slide-down {
        opacity: 1;
        &__item {
            top: 100%;
        }
        [class*="animate-in"] {
            animation: slideDownIn 1s forwards;
        }
        [class*="animate-out"] {
            animation: slideDownOut 1s forwards;
        }
    }
    &-animation-slide-right {
        opacity: 1;
        [class*="animate-in"] {
            animation: slideRightIn 0.6s forwards;
        }
        [class*="animate-out"] {
            animation: slideRightOut 0.6s forwards;
        }
    }
    &-animation-roll-up {
        opacity: 1;
        overflow: hidden;

        > * {
            transform: translateY(-50%);
        }

        [class*="animate-in"] {
            animation: rollUpIn .6s forwards;
        }
        [class*="animate-out"] {
            animation: rollUpOut .6s forwards;
        }
    }
    &-animation-roll-down {
        opacity: 1;
        overflow: hidden;

        > * {
            transform: translateY(-50%);
        }

        [class*="animate-in"] {
            animation: rollDownIn .6s forwards;
        }
        [class*="animate-out"] {
            animation: rollDownOut .6s forwards;
        }
    }
}

@keyframes slideDownIn {
    0% {
        opacity: 0;
        transform: translate3d(0, -30%, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}


@keyframes slideDownOut {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    30% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        transform: translate3d(0, 30%, 0);
    }
}
@keyframes slideRightIn {
    0% {
        opacity: 0;
        transform: translate3d(-3%, 0, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}


@keyframes slideRightOut {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }

    30% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        transform: translate(3%, 0);
    }
}

@keyframes rollUpIn {
    0% {
        top: 150%;
    }

    100% {
        top: 50%;
    }
}


@keyframes rollUpOut {
    0% {
        top: 50%;
    }

    100% {
        top: -150%;
    }
}

@keyframes rollDownIn {
    0% {
        top: -50%;
    }

    100% {
        top: 50%;
    }
}


@keyframes rollDownOut {
    0% {
        top: 50%;
    }

    100% {
        top: 150%;
    }
}
