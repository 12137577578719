.coupon-code {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    // border: 2px dashed gray;
    background: var(--background-background-secondary, #f7f7f7);
    position: relative;
    transition: all 0.15s ease-out;
    transform-origin: top center;
    width: 100%;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);

    &:active {
        transform: scale(0.95);
        background-color: var(--gray-30);
    }

    &__inner {
        display: flex;
        padding: var(--padding0, 0px);
        justify-content: space-between;
        align-items: center;
        gap: var(--padding2, 2px);
        flex: 1 0 0;

        &_left {
            // padding: 0 20px;
            display: flex;
            align-items: center;
            flex-direction: column;
            flex-grow: 1;
        }
        &_right {
            background: linear-gradient(295deg, #3c67f4 2.67%, #ffa8f2 100%);
            color: white;
            height: 75px;
            display: flex;
            align-items: center;
            margin: -2px;
            border-radius: 0 5px 5px 0;
            white-space: nowrap;
            width: 38%;
            justify-content: center;
            font-weight: 500;
            padding: 8px 12px;
            span {
                text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
            }
        }
        :global(svg) {
            height: 24px;
        }
    }
    &__code {
        /* Accessibility / Text Shadow */
        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);

        /* Headlines/Title */
        font-size: 16px;
        font-weight: 600;
        line-height: 32px; /* 133.333% */
    }
    &__expiration {
        color: var(--light-text-secondary, var(--text-text-secondary, #6c6c70));

        font-size: 12px;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
    }
    &__copy-icon {
        // position: absolute;
        // right: 20px;
        // top: 25px;
        // width: 24px;
        // height: 24px;
        display: flex;
        width: 100%;
        align-items: center;
        :global(span) {
            white-space: break-spaces;
            text-align: center;
        }
    }
}
