@use 'sass:math';

.interest-calculator {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 1px 6px 0px rgba(0, 0, 0, 0.25);
    padding: 42px 54px;

    @media screen and (max-width: 768px) {
        padding: 20px;
    }

    &__slider {
        border-radius: 10px;
        border: 1.5px solid var(--gray-60);
        padding: 20px;
        margin-bottom: 10px;

        &__top-row {
            display: flex;
            justify-content: space-between;
        }
    }

    :global(input[type='range']) {
        &::-ms-thumb {
            border-radius: 50%;
            background: #fff;
            border: 1px solid #000;
            height: 20px;
            width: 20px;
            margin-top: -9px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }

        &::-ms-track,
        &::-webkit-slider-runnable-track {
            width: 100%;
            cursor: pointer;
            height: 10px;
        }
    }

    $track-color: transparent !default;
    $thumb-color: white !default;

    $thumb-radius: 12px !default;
    $thumb-height: 24px !default;
    $thumb-width: 24px !default;
    $thumb-shadow-size: 4px !default;
    $thumb-shadow-blur: 4px !default;
    $thumb-shadow-color: rgba(0, 0, 0, 0.2) !default;
    $thumb-border-width: 0px !default;
    $thumb-border-color: #eceff1 !default;

    $track-width: 100% !default;
    $track-height: 8px !default;
    $track-shadow-size: 1px !default;
    $track-shadow-blur: 1px !default;
    $track-shadow-color: rgba(0, 0, 0, 0.2) !default;

    $track-radius: 5px !default;
    $contrast: 5% !default;

    $ie-bottom-track-color: darken($track-color, $contrast) !default;

    @mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
        box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color,
            0 0 $shadow-size lighten($shadow-color, 5%);
    }

    @mixin track {
        cursor: default;
        height: $track-height;
        width: $track-width;
    }

    @mixin thumb {
        @include shadow(
            $thumb-shadow-size,
            $thumb-shadow-blur,
            $thumb-shadow-color
        );
        background: $thumb-color;
        border: $thumb-border-width solid $thumb-border-color;
        border-radius: $thumb-radius;
        box-sizing: border-box;
        cursor: default;
        height: $thumb-height;
        width: $thumb-width;
    }

    [type='range'] {
        -webkit-appearance: none;
        background: transparent;
        margin: math.div($thumb-height, 2) 0;
        width: $track-width;
        background: var(--gray-60);
        background-image: linear-gradient(var(--accent), var(--accent));
        background-size: 0% 100%;
        background-repeat: no-repeat;
        border-radius: 100px;

        &::-moz-focus-outer {
            border: 0;
        }

        &:focus {
            outline: 0;
        }

        &::-webkit-slider-runnable-track {
            @include track;
            background: $track-color;
            border-radius: $track-radius;
        }

        &::-webkit-slider-thumb {
            @include thumb;
            -webkit-appearance: none;
            margin-top: (
                math.div(($track-height), 2) - math.div($thumb-height, 2)
            );
        }

        &::-moz-range-track {
            @include shadow(
                $track-shadow-size,
                $track-shadow-blur,
                $track-shadow-color
            );
            @include track;
            background: $track-color;
            border-radius: $track-radius;
            height: math.div($track-height, 2);
        }

        &::-moz-range-thumb {
            @include thumb;
        }

        &::-ms-track {
            @include track;
            background: transparent;
            border-color: transparent;
            border-width: math.div($thumb-height, 2) 0;
            color: transparent;
        }

        &::-ms-fill-lower {
            @include shadow(
                $track-shadow-size,
                $track-shadow-blur,
                $track-shadow-color
            );
            background: $ie-bottom-track-color;
            border-radius: ($track-radius * 2);
        }

        &::-ms-fill-upper {
            @include shadow(
                $track-shadow-size,
                $track-shadow-blur,
                $track-shadow-color
            );
            background: $track-color;
            border-radius: ($track-radius * 2);
        }

        &::-ms-thumb {
            @include thumb;
            margin-top: math.div($track-height, 4);
        }
    }
}
